<template>
  <v-app app :key="lang">
    <HeaderComponent></HeaderComponent>
    <v-main>
      <router-view></router-view>
      <div id="fb-root"></div>
    </v-main>
    <FooterComponent></FooterComponent>
  </v-app>
</template>

<script>
const HeaderComponent = () => import('./components/main/HeaderComponent')
const FooterComponent = () => import('./components/main/FooterComponent')

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      lang: null
    }
  },
  computed: {
    selectedLang () {
      return this.$store.getters['app/getSelectedLocale']
    }
  },
  watch: {
    selectedLang: {
      immediate: true,
      handler (selectedLang) {
        this.$nextTick(() => {
          this.lang = selectedLang
        })
        document.documentElement.setAttribute('lang', selectedLang)
      }
    }
  }
}
</script>

<style lang="scss">
ul {
  padding-left: 0 !important;
}
@import "assets/styles/variables";
  @mixin sectionTitle($size, $letterSpacing) {
    font-size: $size;
    font-weight: 500;
    text-align: center;
    letter-spacing: $letterSpacing;
    line-height: normal;
    color: $lightColor;
  }
  @mixin sectionSubtitle($size, $letterSpacing) {
    font-size: $size;
    position: relative;
    display: inline-block;
    font-weight: normal;
    text-align: center;
    letter-spacing: $letterSpacing;
    line-height: normal;
    color: $darkTextColor;
    z-index: 1;
  }
  @mixin numberWrapper($background, $border) {
    width: 40px;
    height: 40px;
    border: $border;
    background-color: $background;
    transform: rotateZ(45deg);
  }
  @mixin number($color) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 2rem;
    transform: rotateZ(-45deg);
    color: $color;
  }
  .number-wrapper {
   @include numberWrapper($lightColor, unset);
  }
  .number-wrapper-2 {
    @include numberWrapper(unset, 2px solid $lightColor);
  }
  .number {
    @include number(#ffffff)
  }
  .number-2 {
    @include number($lightColor);
    transform: rotateZ(-45deg) translateY(-2px);
  }
  * {
    font-family: $body-font-family;
    scroll-behavior: smooth;
  }
  .container {
    max-width: 1200px !important;
    padding: 20px 15px;
    margin: 0 auto;
  }
  .main-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    background-color: $lightColor;
    min-height: 60px;
    border-radius: 8px;
    color: #ffffff;
    line-height: normal;
    padding: 0 1rem;
  }
  .section-title {
    @include sectionTitle(2rem, 1px)
  }
  .section-title-sm {
    @include sectionTitle(1.8rem, normal)
  }
  .section-subtitle {
    @include sectionSubtitle(1.5rem, 0)
  }
  .section-subtitle-sm {
    @include sectionSubtitle(1.35rem, normal);
    display: block;
    margin: 0 auto;
  }
  .decor-text {
    font-family: 'Caveat',serif;
    font-size: 1.6rem;
    color: $lightColor;
    font-weight: normal;
    line-height: normal;
  }
</style>
