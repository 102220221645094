import ru from 'vuetify/lib/locale/ru'

export default {
  ...ru,
  locale: {
    switcherLabel: 'Выберите язык'
  },
  main: {
    preview: {
      mainTitle: 'Онлайн консультации с профессиональным психологом',
      decorTextPart1: 'c любого устройства,',
      decorTextPart2: 'без установки дополнительных приложений',
      previewMainBtn: 'Выбрать психолога',
      additionText: 'единая цена {0} грн за сессию ~ 50мин',
      dignityTitle1: '18 000+ часов',
      dignityTitle2: 'работы с клиентами',
      dignityTitle3: '90+ лет',
      dignityAdditionText1: 'общий опыт наших терапевтов',
      dignityAdditionText2: '100%',
      dignityAdditionText3: 'конфиденциальность'
    },
    about: {
      sectionTitle: 'консультации с психологом',
      sectionSubtitle: 'помогут улучшить все сферы вашей жизни',
      cards: {
        card1: 'Избавится от ПТСР, забыть о тревожности и обрести гармонию в душе',
        card2: 'Выровнять свое эмоциональное состояние и справиться со стрессом',
        card3: 'Повысить собственную самооценку и принять себя',
        card4: 'Научиться отстаивать собственные границы и говорить "Нет"',
        card5: 'Построить гармоничные отношения с близкими людьми',
        card6: 'Найти свое предназначение, справиться с прокрастинацией и выгоранием'
      }
    },
    check: {
      sectionTitle: 'Мы работаем только с профессиональными психологами',
      sectionSubtitle: 'строго проверяем у специалистов',
      decorText: 'Не все специалисты проходят наш отбор',
      button: 'Подобрать своего психолога',
      checkList: {
        itemTitle1: 'Образование',
        itemDescription1: 'Только высшее психологическое и дополнительное психотерапевтическое',
        itemTitle2: 'Опыт работы',
        itemDescription2: 'Более 3-х лет практики, наличие определенного количества часов супервизий и личной терапии',
        itemTitle3: 'Собеседование',
        itemDescription3: 'Каждый психолог проходит собеседование для определения уровня профессионализма',
        itemTitle4: 'Повышение квалификации',
        itemDescription4: 'Наши специалисты регулярно проходят семинары от ведущих европейских специалистов'
      }
    },
    steps: {
      sectionTitle: 'Как работает наш сервис',
      decorText: 'Если вам не подошел ни один из предложенных специалистов, вы можете воспользоваться ручным подбором',
      button: 'Подобрать специалиста',
      stepsList: {
        itemTitle1: 'Заполните анкету',
        itemDescription1: 'Укажите в анкете контактные данные и отметьте проблемы ' +
          'с которыми вы хотели бы разобраться. Так же можете указать пожелания' +
          ' к специалисту если они у вас есть.',
        itemTitle2: 'Выберите психолога',
        itemDescription2: 'Сервис предложит вам на выбор  5-6 психотерапевтов, которые работают именно с вашими запросами',
        itemTitle3: 'Укажите время',
        itemDescription3: 'Забронируйте удобное время для консультации',
        itemTitle4: 'Начните консультацию',
        itemDescription4: 'В назначенное время зайдите в свой кабинет на сайте для начала сессии. Дополнительные приложения устанавливать не надо.'
      }
    },
    usability: {
      mainTitle: 'Наш сервис простой и удобный в использовании',
      sectionSubtitle: 'Мы делаем психотерапию удобной',
      button: 'Попробовать',
      usabilityList: {
        itemTitle1: 'Консультация {0} грн',
        itemDescription1: 'Действует единая цена на консультации всех специалистов. Мы сделали качественную психотерапию более доступной',
        itemTitle2: 'Защищенный встроенный видеочат',
        itemDescription2: 'Видеочат доступен в защищенном личном кабинете. Вы можете подключиться с любого устройства. Соблюдается полная конфиденциальность',
        itemTitle3: 'Удобный личный кабинет',
        itemDescription3: 'Вы легко можете управлять своими консультациями (назначать, переносить, отменять). Устанавливайте время удобное для вас'
      }
    },
    faq: {
      title: 'Вопросы-ответы',
      questionWhy: 'Для чего нужны консультации психолога?',
      answerWhy: 'Что бы разобраться в своей внутренней реальности и структурировать свой опыт. Находить выходы из конфликтов, решать проблемы и достигать цели. Обрести внутренние силы, чтобы изменить себя и свою жизнь. Открыть свой потенциал и самореализоваться в жизни.',
      questionHow: 'Как работает психотерапия?',
      answerHow: 'В процессе психотерапии вы научитесь лучше понимать свои реакции на других и то, как вы относитесь к себе. Благодаря психотерапии вы получите новый опыт взаимоотношений, и сможете узнать, как улучшить отношения, в том числе и отношения с самим собой. Психотерапия дает удивительные возможности раскрывать свой внутренний мир.',
      questionCriteria: 'По каким критериям отбираются психотерапевты?',
      answerCriteria: 'У нас есть ряд требований: высшее психологическое образование, опыт консультирования не менее 3 лет и наличие рекомендаций от кого-либо из признанных членов профессионального сообщества. С каждым из терапевтов мы проводим собеседование.',
      questionCost: 'Сколько это стоит?',
      answerCost: '{0} гривен за 50-минутную сессию.',
      questionDuration: 'Почему сессия длится 50 минут?',
      answerDuration: 'Консультация с психологом – довольно затратное с точки зрения эмоций мероприятие и продолжать её более, чем 50 минут, может быть тяжело как для психотерапевта, так и для клиента. ',
      questionPayment: 'Как работает оплата?',
      answerPayment: 'Вы привязываете свою карточку при регистрации. Деньги за сессию списываются за 12 часов до ее начала.',
      questionPaymentDesc: 'Почему деньги списываются до оказания услуги?',
      answerPaymentDesc: 'Сессия, которую клиент пропускает, предупреждая об этом менее, чем за 12 часов, оплачивается. У клиентов неизбежно возникает сопротивление при приближении к «секретам» его психики. Клиент начинает пропускать сессии, придумывая тысячи причин, что б не прийти на сессию. Хорошей работы не получается. Поэтому, мы и внесли такой элемент дисциплины. \n' +
        'Мы только за качественную и результативную психотерапию.\n',
      questionPlans: 'Что делать, если у меня поменялись планы после списания денег?',
      answerPlans: 'К сожалению, ничего – если вы предупреждаете о переносе сессии менее, чем за 12 часов до её начала, то деньги не возвращаются. \n' +
        'Это правило мы позаимствовали у Фрейда.\n',
      questionShift: 'Как мне перенести сессию?',
      answerShift: 'В своем личном кабинете. Вы свободно управляете датой и временем.',
      questionTherapist: 'Меня не устроил терапевт, что мне делать?',
      answerTherapist: 'Попробуйте поработать с другим. У нас на Платформе все терапевты высокой квалификации. Но, есть еще и личное восприятие человека. Мы это понимаем, поэтому и предлагаем на выбор несколько терапевтов..',
      questionFinish: 'Я хочу закончить работу с платформой – как мне это сделать?',
      answerFinish: 'Для этого просто нужно отвязать свою карту в своем личном кабинете.',
      questionConfidential: 'Все мои консультации с психологом конфиденциальные?',
      answerConfidential: 'Естественно.  Всё что происходит на сессии  – только между вами и вашим психологом. Более того, видеосвязь между вами и терапевтом осуществляется напрямую между вашими браузерами. Ни у кого нет возможности как-то подсмотреть или подслушать вашу сессию.',
      haveQuestion: 'У меня остались вопросы – что мне делать?',
      inTouch: 'Мы на связи '
    },
    contacts: {
      title: 'Контакты',
      chat: 'Чат на сайте',
      totalQuestions: 'Общие вопросы и отел заботы о клиентах',
      corporateClients: 'Для корпоративных клиентов',
      psychologistsPartnerships: 'Психологам по вопросам сотрудничества',
      advertising: 'Сотрудничество с СМИ, спецпроекты и реклама',
      mainTitle: 'Скорая психологическая помощь',
      descriptionText: '"Платформа" не предоставляет услуги скорой психологической помощи.\n' +
        '          Если ваши проблемы нужно решать срочно или вашей жизни и здоровью что-то угрожает,\n' +
        '          пожалуйста, обратитесь в одну из этих организаций:',
      contactsList: {
        itemDescription1: 'Горячая линия по предупреждению суицидов и психологической поддержки Lifeline Ukraine',
        itemDescription2: 'Горячая линия по вопросам предотвращения домашнего насилия, торговли людьми и гендерной дискриминации',
        itemDescription3: 'Линия помощи военным, жертвам насилия, всем нуждающимся в психологической поддержке.',
        itemDescription4: 'Национальная детская горячая линия'
      }
    },
    header: {
      menuItem1: 'О проекте',
      menuItem2: 'Наши психологи',
      menuItem3: 'Для психологов',
      menuItem4: 'Блог',
      menuItem5: 'Для бизнеса',
      // menuItem6: 'О нас',
      EnterButton: 'Вход'
    },
    footer: {
      question: 'Остались вопросы?',
      contactUs: 'Мы всегда на связи',
      legal: {
        mainText:
          ' <b>Важный момент.</b> "Начало" — это не скорая психологическая\n' +
          '            помощь. Если у вас серьезные, угрожающие вашей жизни проблемы,\n' +
          '            которые требуют немедленного решения, вам лучше обратиться в\n' +
          '            какую-либо из этих организаций.',
        userAgreementPart1: 'Регистрация на сайте означает согласие с',
        userAgreementLink: 'пользовательским соглашением.',
        userAgreementPart2: 'Политика в отношении обработки и защиты ',
        userAgreementLink2: 'персональных данных',
        terms: 'При использовании любых материалов этого сайта, ссылка на источник обязательна.',
        protected: 'Все права защищены!'
      },
      footerMenu: {
        FAQ: 'Вопросы-Ответы',
        Contacts: 'Контакты',
        workOffers: 'Работа в Начало',
        blog: 'БЛОГ',
        ourPsychologists: 'Наши психологи',
        forBusiness: 'Начало для бизнеса',
        aboutProject: 'О проекте'
      }
    }
  },
  view: {
    psychologists: {
      specialization: {
        // psychologist filters
        life_event_problems: 'События жизни',
        mental_health_condition_problems: 'Состояние',
        relationship_problems: 'Отношения',
        career_problems: 'Работа, карьера, учеба'
        /******************************************/
      }
    },
    psychDescrModal: {
      showAll: 'Показать полностью..',
      book: 'Забронировать',
      close: 'Закрыть',
      experiense: 'Опыт работы '
    },
    forPsychologists: {
      registrationButton1: 'Сотрудничать',
      mainTitle: 'Хотите стать частью команды?',
      subtitle: 'Сосредоточьтесь исключительно на своей психологической практике, а мы\n' +
        '          возьмем на себя организацию и продвижение ваших услуг',
      decorText: 'Работайте где и когда удобно',
      requirements: {
        title: 'Мы ждем настоящих психологов',
        subtitle: 'требования к психологам',
        listTitle: [
          'Образование',
          'Опыт работы',
          'Наличие ФЛП'
        ],
        listDescription: [
          'Только высшее психологическое. Наличие образования в одном из методов (обучение длительностью 300+ часов)',
          'Более 3-х лет практики, а так же прохождение супервизий и личной терапии',
          'Вы должны быть зарегистрированы или иметь возможность открыть ФЛП'
        ]
      },
      advantages: {
        title: 'Работая в Платформе вы получите',
        listTitle: [
          'Клиентов',
          'Свободу',
          'Комьюнити'
        ],
        listDescription: [
          'Вам не нужно заниматься своим продвижением. Мы приводим клиентов готовых к работе',
          'Вы можете работать из любой точки мира и сами решаете, когда и сколько клиентов готовы взять',
          'Вы попадаете в сообщество профессионалов где можете обмениваться знаниями и опытом'
        ],
        button: 'Заполнить анкету',
        decorText: 'Вы занимаетесь любимой работой, остальное мы берем на себя'
      },
      start: {
        title: 'Как начать сотрудничество',
        listTitle: [
          'Зарегистрируйтесь на платформе',
          'Пройдите собеседование',
          'Приступайте к работе'
        ],
        listDescription: [
          'Заполните  анкету. После отправки мы свяжемся с вами и договоримся о собеседовании',
          'Собеседование длится 30 минут и проходит в удобное для вас время',
          'Настраивайте личный кабинет и принимайте первых клиентов'
        ]
      },
      FAQ: 'Вопросы и ответы',
      question1: 'Как долго рассматривается анкета?',
      answer1: 'В среднем мы рассматриваем анкету одну - две недели',
      question2: 'Какие этапы после рассмотрения анкеты?',
      answer2: 'Если все условия соблюдены, то мы присылаем на ваш емейл приглашение на собеседование. ' +
        'Собеседование проводит один из наших кураторов (опытный психотерапевт, супервизор)',
      question3: 'Какие условия принятия анкеты на рассмотрение?',
      answer3: [
        '- Высшее психологическое образование',
        '- Подтвержденное сертификатами и дипломами образование в одном из психотерапевтических методов от 300 часов',
        '- Личная практика от 3-х лет',
        '- Наличие регулярных супервизий',
        '- Опыт личной психотерапии'
      ],
      question4: 'Сколько психотерапевт получает за сессию на Платформе?',
      answer4: 'В среднем за одну сессию с клиентом специалист получает около {0} грн'
    },
    blog: {
      categories: 'Категории',
      title: 'Блог',
      subtitle: 'Данный раздел находится в разработке.',
      localisationWarning: 'Статья написана на 1-м языке',
      posted: 'Опубликован',
      close: 'Закрыть',
      readMore: 'Читать статью полностью',
      nextPost: 'Следующая статья',
      previousPost: 'Предыдущая статья',
      header:
        'Мудрый совет, интересная история, список полезных лайфхаков или научная статья. Кто знает, какое знание придется вам по душе сегодня?',
      subtitle1: 'Хорошо, что у нас есть выбор.',
      search: 'Поиск по статьям',
      subtitle2:
        'Лучшие статьи помогут найти ответы на непростые и очень важные вопросы, отдохнуть душой и познакомиться с мнением экспертов проекта.',
      andMore: '...и еще много интересного'
    },
    business: {
      preview: {
        mainTitle: 'Психологическая поддержка ваших сотрудников',
        subtitle: 'Помогаем в повышении эффективности трудовой деятельности вашего коллектива',
        decorTextPart: 'Создать благоприятный психологический климат в коллективе',
        previewMainBtn: 'Оставить заявку',
        dignityTitle: 'Забота о ментальном здоровье работников - это инвестиция',
        dignity1: 'уменьшение текучести кадров',
        dignity2: 'уменьшение напряженности и конфликтности в коллективе',
        dignity3: 'повышение производительности труда коллектива',
        dignity4: 'повышение стрессоустойчивости сотрудников',
        formTitle: 'Оставьте свои контактные данные, и мы свяжемся с вами для обсуждения деталей.',
        companyName: 'Название компании',
        employees: 'Количество сотрудников',
        phone: 'Телефон',
        contactChannel: 'Предпочитаемый способ связи',
        legalText: 'даю согласие на ',
        legalSubtext: ' обработку персональных данных',
        loading: 'Загрузка'
      },
      solve: {
        mainTitle: 'Платформа – проверенное средство решения многих проблем',
        secondTitle: 'Инвестируйте в психологическое здоровье сотрудников и они будут поражать вас результатами',
        decorText: 'Поддерживайте свою команду',
        list: {
          description1: 'Нехватка мотивации работников для выполнения поставленных целей',
          description2: 'Выгорание и прокрастинация персонала на рабочем месте',
          description3: 'Недоверие и деловые претензии членов коллектива друг к другу',
          description4: 'Невысокая степень эмоционального включения и взаимопомощи',
          description5: 'Влияние личных проблем работника на производительность труда',
          description6: 'Отсутствие осознания и принятия ответственности на себя за состояние дел в коллективе каждым из его членов'
        }
      },
      find: {
        title: 'Подберем психолога,',
        subtitle: 'с которым сотруднику будет комфортно',
        btn: 'Узнать больше о сервисе',
        list: {
          description1: 'Сотрудник заполняет анкету',
          description2: 'Алгоритм предложит 6 специалистов отвечающих запросу',
          description3: 'Сотрудник выбирает психолога и время консультации'
        }
      },
      why: {
        title: 'Почему Платформа нравится сотрудникам',
        list: {
          title1: 'Единая цена',
          description1: 'Действует единая цена на консультации всех специалистов. Мы сделали качественную психотерапию более доступной',
          title2: 'Защищенный встроенный видеочат',
          description2: 'Видеочат доступен в защищенном личном кабинете. Можно подключиться с любого устройства. Соблюдается полная конфиденциальность',
          title3: 'Удобный личный кабинет',
          description3: 'Легко управлять своими консультациями (назначать, переносить, отменять). Устанавливайте удобное для вас время'
        }
      },
      selection: {
        title: 'Мы тщательно отбираем психологов,',
        subtitle: 'и работаем с лучшими',
        decorText1: 'Сотрудник решивший психологические проблемы',
        decorText2: ' на 75% лучше концентрируется на своих рабочих задачах',
        btn: 'Поднять эффективность своих сотрудников',
        list: {
          title1: 'Проверка образования',
          description1: 'Наши специалисты имеют высшее психологическое и психотерапевтическое образование. В этой сфере много недоучек, и нам важно их отсеять.',
          title2: 'Опыт работы',
          description2: 'Более трех лет практики, наличие определенного количества часов супервизий и личной терапии',
          title3: 'Личное собеседование',
          description3: 'Тщательно проверяем знания и навыки каждого специалиста',
          title4: 'Регулярное обучение',
          description4: 'Мы проводим супервизии и семинары для постоянного развития наших психологов'
        }
      },
      comfortable: {
        title: 'С нами удобно работать',
        list: {
          title1: 'Прозрачная отчетность',
          description1: 'Подробная информация о количестве проведенных сессий для ваших сотрудников',
          title2: 'Контроль за списком участников',
          description2: 'Сгенерированный промокод позволяет отслеживать, чтобы только ваша команда имела доступ к сессиям на Платформе',
          title3: 'Оплата только за использование',
          description3: 'Мы берем оплату только за фактически проведенные сессии для вашей команды',
          title4: 'Произвольная модель компенсации',
          description4: 'Периодические сессии за счет компании, фиксированный процент от каждой сессии, стопроцентная компенсация – любая схема, которая принята в вашей компании.'
        }
      },
      faq: {
        FAQ: 'Вопросы и ответы',
        subtitle: 'Остались вопросы - пишите, мы всегда на связи',
        question1: 'Чем Платформа лучше постоянного психолога в офисе?',
        answer1: 'Доверие сотрудников к онлайн терапии значительно выше, потому что выше уровень конфиденциальности. ' +
          'Во-первых никто не знает кто из сотрудников пользуется услугами психолога ' +
          '(с психологом в офисе это трудно обеспечить), а во-вторых - не возникает сомнений в том, ' +
          'что никакая личная информация не попадет к руководителю',
        question2: 'Как донести сотрудникам, что психотерапия это полезно?',
        answer2: 'Мы можем предоставить вам под запрос материалы о пользе сессий с психотерапевтом.',
        question3: 'Зачем моей компании сотрудничать с Платформой?',
        answer3: 'Личные проблемы сотрудника будут отражаться на его мотивированности и трудоспособности. Регулярные встречи с ' +
          'психологом помогут членам вашей команды наладить то, что давно их беспокоит. Таким образом увеличивается их производительность' +
          ' на рабочем месте. Сотрудники очень лояльны к компании и руководителям, которые помогают им в личностном и профессиональном росте.',
        question4: 'Все, что рассказывается на сессии, конфиденциально?',
        answer4: 'Все, что происходит на сессии - только между сотрудником и его психологом. Видеосвязь осуществляется непосредственно ' +
          'между устройством клиента и устройством психолога, поэтому даже нет технической возможности что-либо увидеть или услышать.',
        question5: 'Как технически работает Платформа для бизнеса?',
        answer5: ' Мы создаем для вашей компании промокод, который работник вводит при регистрации. ' +
          'В промокоде заложены условия и ограничения по использованию, которые вы сами определите.',
        question6: 'Сотрудник может кому-нибудь передать свой промокод?',
        answer6: 'Мы ограничили использование промокода только в связке с корпоративным e-mail.'
      },
      request: {
        title: 'Оставьте заявку, и мы вам предложим лучшие варианты, как усилить свою команду.',
        btn: 'Оставить заявку'
      }
    },
    aboutUs: {
      heroTitle: 'Приветствуем вас на Платформе!',
      heroSubtitle: 'Если вы здесь, то уже на шаг приблизились к внутреннему спокойствию',
      forTitle: 'Мы создали этот сервис для людей,',
      forDescription1: 'которые хотят уравновесить внутреннее состояние и избавиться\n' +
        '            от психологического бремени. Мы не гадаем на картах и не фотографируем вашу ауру.',
      forDescription2: 'Все, что мы делаем для вас - подбираем надежных и профессиональных специалистов\n' +
        '            с опытом, которые любят свою работу и несут за неё ответственность.',
      forText: '"Платформа" - это психотерапевты, имеющие высшее психологическое образование\n' +
        '          и не менее 3-х лет консультативной практики. Здесь нет проходимцев, которые рвутся к профессии,\n' +
        '        получив сертификат после 2-х месячных курсов.',
      whyTitle: 'Почему так?',
      whySubtitle: 'Облегченно выдыхайте, вы в безопасности!',
      whyText: 'Мы ощущаем сегодняшнюю потребность в качественной консультации, и именно поэтому создали сервис,\n' +
        '        где профессиональную психологическую помощь оказывают проверенные эксперты. Вам не о чем волноваться.',
      whyArgument: 'аргументов, чтобы попробовать уже сейчас:',
      whyList: {
        description1: 'Каждый специалист проходит собеседование с соучредителем сервиса, имеющим 12-ти летний опыт в сфере психологии;',
        description2: 'Для индивидуальной консультации требуется только интернет. Консультации проходят непосредственно на платформе,' +
          'вам не нужно устанавливать дополнительные программы;',
        description3: 'Фиксированная и единая цена за консультацию;',
        description4: 'Оплата за сессию прямо на платформе;',
        description5: 'Индивидуальный подход и гарантия результата.'
      },
      whyButton: 'Подобрать психолога'
    },
    auth: {
      psychologist: {
        Schedule: {
          title: 'Расписание',
          subtitle: 'Тут Вы можете выбрать дни, когда будете доступны для отображения пациентам.',
          description: 'Расписание отображается в формате - текущий день + 6 дней.',
          btnSave: 'Сохранить',
          btnEdit: 'Обновить'
        }
      }
    }
  },
  pageNotFound: {
    Oops: 'Уупс..',
    description: 'Страница которую Вы ищите, не существует. '
  }
}
