import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: () => import('../views/MainPage.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/psychologist',
    name: 'forPsychologists',
    component: () => import('../views/ForPsychologist')
  },
  {
    path: '/blog/',
    name: 'blog-home',
    component: () => import('../components/main/BlogComponent')
  },
  {
    path: '/blog/posts/:slug',
    name: 'blog-post',
    component: () => import('../components/main/PostComponent')
  },
  {
    path: '/therapists',
    name: 'psychologists',
    component: () => import('../views/Psychologists')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/Business')
  },
  {
    path: '*',
    component: () => import('../views/pageNotFound')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
