import Axios from 'axios'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'

const options = {
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

const httpClient = Axios.create(options)

httpClient.interceptors.request.use((config) => {
  if (store.getters['app/getSelectedLocale']) {
    config.headers['Accept-Language'] = store.getters['app/getSelectedLocale']
  }

  return config
})

httpClient.interceptors.response.use(
  (response) => {
    if (response.config.headers.authorization) {
      store.commit(
        'user/SET_AUTH_TOKEN',
        response.config.headers.authorization
      )

      return response
    } else return response
  },
  (error) => {
    const { data, status } = error.response

    let message = ''

    if (status === 401) {
      store.commit('user/SET_LOGOUT')

      message = 'Увы, но Вы были разлогинены :с'
      router.push('/auth')
    } else if (status === 422) {
      if (data.errors) {
        Object.keys(data.errors).forEach((itemMessage, index) => {
          message = data.errors[itemMessage][index]
        })
      } else {
        message = data.message
      }
    } else return Promise.reject(data)

    Vue.swal({
      text: message,
      icon: 'error',
      timer: 3500,
      toast: true,
      position: 'top-right',
      showConfirmButton: false
    })
  }
)

const ApiClient = {
  get (url, conf = {}) {
    return httpClient.get(url, conf)
  },

  put (url, data = {}, conf = {}) {
    return httpClient.put(url, data, conf)
  },

  delete (url, data = {}) {
    return httpClient.delete(url, data)
  },

  post (url, data = {}, conf = {}) {
    return httpClient.post(url, data, conf)
  }
}

export default ApiClient
