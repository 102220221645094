import ApiClient from '@/libs/http-client'

const AppServices = {
  endpoints (route, params) {
    const url = {
      getPsychologist: `api/psychologists/paginated?page=${params.page}&filter=${params.filter}`,
      getBlogArticles: `api/blog/posts?page=${params.page}`,
      getPost: `api/blog/posts/${params.slug}`,
      getCategoriesForUsers: 'api/blog/categories',
      searchBlogByTitle: `api/blog/posts?search=${params.title}&${params.categories}&page=${params.page}`,
      getPublishedBlogsList: `api/blog/posts/published?page=${params.page}`,
      getPsychologistFilter: 'api/general-questions'
    }

    return url[route]
  },

  get (url, data) {
    return ApiClient.get(this.endpoints(url, data))
  },
  post (url, params = {}, data, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config)
  }
}

export default AppServices
