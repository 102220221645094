import AppService from '@/services/api/app.service'

const state = {
  isAuth: null,
  authToken: null,
  sessionId: null,
  sessionCookie: null,
  psychologists: [],
  user: {},
  filters: {},
  post: {},
  categories: {},
  selectedCategories: [],
  publishedBlogsList: {
    meta: {
      last_page: 1
    }
  }
}

const mutations = {
  SET_PSYCHOLOGISTS (state, payload) {
    state.psychologists = payload
  },
  SET_USER (state, payload) {
    state.user = payload.user
    state.isAuth = true
    state.authToken = payload.user.authToken
  },
  SET_AUTH_TOKEN (state, payload) {
    state.isAuth = true
    state.authToken = payload
  },
  SET_SESSION_ID (state, payload) {
    state.sessionId = payload
  },
  SET_LOGOUT (state) {
    state.isAuth = false
    state.authToken = null
    state.user = {}
  },
  SET_USER_VERIFY (state) {
    state.user.is_verified = true
  },
  SET_SESSION_COOKIE (state, payload) {
    state.sessionCookie = payload
  },
  SET_ARTICLES (state, payload) {
    state.blogArticles = payload
  },
  SET_POST (state, payload) {
    state.post = payload
  },
  SET_PUBLISHED_BLOGS_LIST (state, payload) {
    state.publishedBlogsList = payload
  },
  SET_CATEGORIES (state, payload) {
    state.categories = payload
  },
  SET_SELECTED_CATEGORIES (state, payload) {
    state.selectedCategories = payload
  },
  SET_FILTERS (state, payload) {
    state.filters = payload
  }
}
const actions = {
  // eslint-disable-next-line no-empty-pattern
  getPsychologists ({ commit }, data) {
    return new Promise((resolve, reject) => {
      AppService.get('getPsychologist', data)
        .then((response) => {
          commit('SET_PSYCHOLOGISTS', response.data.data)
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  },
  getArticles ({ commit }, data) {
    return new Promise((resolve, reject) => {
      AppService.get('getBlogArticles', data)
        .then((response) => {
          commit('SET_ARTICLES', response.data)
          return resolve(response)
        }).catch((error) => reject(error))
    })
  },
  getPost ({ commit }, data) {
    return new Promise((resolve, reject) => {
      AppService.get('getPost', data)
        .then((response) => {
          commit('SET_POST', response.data.data)
          return resolve(response)
        }).catch((error) => reject(error))
    })
  },
  searchBlogByTitle ({ commit }, params) {
    return new Promise((resolve, reject) => {
      AppService.get('searchBlogByTitle', params)
        .then((response) => {
          commit('SET_PUBLISHED_BLOGS_LIST', response.data)
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  },
  getPsychologistFilter ({ commit }) {
    return new Promise((resolve, reject) => {
      AppService.get('getPsychologistFilter', {})
        .then((response) => {
          commit('SET_FILTERS', response.data.data)
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  },
  getCategoriesListForUser ({ commit }) {
    return new Promise((resolve, reject) => {
      AppService.get('getCategoriesForUsers', {})
        .then((response) => {
          commit('SET_CATEGORIES', response.data)
          return resolve('')
        })
        .catch((error) => reject(error))
    })
  }
}

const getters = {
  getUser: (state) => state.user,
  getAuthToken: (state) => state.authToken,
  getSessionCookie: (state) => state.sessionCookie,
  getSessionId: (state) => state.sessionId,
  isAuth: (state) => state.isAuth,
  isVerified: (state) => state.user.is_verified,
  getPsychologistId: state => state.psychologistId,
  getPsychologists: state => state.psychologists,
  // getAllBlogsList: (state) => state.allBlogsList,
  getPublishedBlogsList: (state) => state.publishedBlogsList,
  getPost: state => state.post,
  getSelectedCategoriesList: (state) => state.selectedCategories,
  getCategoriesList: (state) => state.categories,
  getFiltersList: (state) => state.filters
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
