const state = {
  selectedLang: 'uk'
}

const mutations = {
  CHANGE_LOCALE (state, payload) {
    state.selectedLang = payload
  }
}

const actions = {
  changeLocale ({ commit }, data) {
    commit('CHANGE_LOCALE', data)
  },
  setDefaultLocale ({ commit }, lang) {
    commit('CHANGE_LOCALE', lang)
  }
}

const getters = {
  getSelectedLocale: (state) => state.selectedLang
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
