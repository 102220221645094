import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@fortawesome/fontawesome-free/css/all.css'

import { messages, defaultLanguage } from './locales/index'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      option: {
        customProperties: true
      },
      light: {
        primary: '#00A4B7'
      }
    }
  },
  lang: {
    locales: {
      en: messages.en,
      uk: messages.uk,
      ru: messages.ru
    },
    current: defaultLanguage
  },
  icons: {
    iconfont: 'fa'
  }
})
